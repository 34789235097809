import React, { lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout} from './layouts';
import Resources from './service/resources';
import './i18n'


const Login = lazy(() => import('./views/Login'));
const NotFoundView = lazy(() => import('./views/NotFound'));
const AdminDashboard = lazy(() => import('./views/AdminDashboard'));
const Create = lazy(() => import('./views/Delivery/Create'));
const DeliveryList = lazy(() => import('./views/Delivery/DeliveryList'));
const InvoiceList = lazy(() => import('./views/Delivery/Invoice/InvoiceList'));
const Zone = lazy(() => import('./views/Delivery/Zone'));
const DeliveryDetail = lazy(() => import('./views/Delivery/DeliveryList/DeliveryDetail'));
const Holiday = lazy(() => import('./views/Settings/Holiday'));
const PriceMethod = lazy(() => import('./views/Financial/PriceMethod'));
const Accounting = lazy(() => import('./views/Financial/Accounting'));
const Promotion = lazy(() => import('./views/Financial/Promotion'));
const DepositOS = lazy(() => import('./views/Financial/Accounting/DepositOS'));
const RefundOS = lazy(() => import('./views/Financial/Accounting/RefundOS'));
const OSBalance = lazy(() => import('./views/Financial/Accounting/OSBalance'));
const EmployeeCollectedClearance = lazy(() => import('./views/Financial/Accounting/EmployeeCollectedClearance'));
const User = lazy(() => import('./views/Users/User'));
const AddressBook = lazy(() => import('./views/Users/AddressBook'));
const UserCreate = lazy(() => import('./views/Users/User/Create'));
const AddressBookCreate = lazy(() => import('./views/Users/AddressBook/Create'));
const Expense = lazy(() => import('./views/Organization/Expense'));
const Branch = lazy(() => import('./views/Organization/Branch'));
const ManagerAccountCreate = lazy(() => import('./views/Organization/Branch/Create'));
const Contract = lazy(() => import('./views/Organization/Contract'));
const PickupCreate = lazy(() => import('./views/Pickup/Create'));
const PickupList = lazy(() => import('./views/Pickup/PickupList'));
const PickupDetail = lazy(() => import('./views/Pickup/PickupList/PickupDetail'));
const DeliveryWay = lazy(() => import('./views/Pickup/Create/Way'));
const PickupInvoiceList = lazy(() => import('./views/Pickup/Invoice/PickupInvoiceList'));
const AccountZone = lazy(() => import('./views/Financial/Accounting/AccountZone'));
const Payment = lazy(() => import('./views/Pickup/Invoice/Payment'));
const PaymentHistory = lazy(() => import('./views/Pickup/Invoice/History'));
const MerchantList = lazy(() => import('./views/Users/User/MerchantList'));
const MerchantDetail = lazy(() => import('./views/Users/User/MerchantDetail'));
const DeliverymanList = lazy(() => import('./views/Users/User/DeliverymanList'));
const DeliverymanDetail = lazy(() => import('./views/Users/User/DeliverymanDetail'));
const WayReportHome = lazy(() => import('./views/Reporting/WayReportHome'));
const IncomeReport = lazy(() => import('./views/Reporting/IncomeReport'));
const ExpenseReport = lazy(() => import('./views/Reporting/ExpenseReport'));
const ToDeliverReport = lazy(() => import('./views/Reporting/ToDeliverReport'));
const OverdueCountingReport = lazy(() => import('./views/Reporting/OverdueCountingReport'));
const OverdueByDeliverymanReport = lazy(() => import('./views/Reporting/OverdueByDeliverymanReport'));
const OverdueByMerchantReport = lazy(() => import('./views/Reporting/OverdueByMerchantReport'));
const ActiveWayCountByTown = lazy(() => import('./views/Reporting/ActiveWayCountByTown'));
const PriceMethodDetail = lazy(() => import('./views/Financial/PriceMethod/PriceMethodDetail'));
const PriceMethodCreate = lazy(() => import('./views/Financial/PriceMethod/PriceMethodCreate'));
const UserProfile = lazy(() => import('./views/Users/User/UserProfile'));
const POICreate = lazy(() => import('./views/Settings/POI/Create'));
const POIList = lazy(() => import('./views/Settings/POI'));
const ZoneCreate = lazy(() => import('./views/Delivery/Zone/ZoneCreate'));
const ZoneDetail = lazy(() => import('./views/Delivery/Zone/ZoneDetail'));
const TrackingMap = lazy(() => import('./views/AdminDashboard/components/TrackingMap'));
const ChatBox = lazy(() => import('./views/Chat/ChatBox'));
const ExclusivePricing = lazy(() => import('./views/Financial/PriceMethod/ExclusivePricing'));
const TownsList = lazy(() => import('./views/Settings/Towns'));
const TownCreate = lazy(() => import('./views/Settings/Towns/Create'));
const AccessRequest = lazy(() => import('./views/Login/AccessRequest'));
const PickupReceiptList = lazy(() => import('./views/Pickup/Invoice/PickupReceiptList'));
const TransitList = lazy(() => import('./views/Delivery/TransitList'));
const TransitDetail = lazy(() => import('./views/Delivery/TransitList/TransitDetail'));
const TransitInboundList = lazy(() => import('./views/Delivery/TransitList/TransitInboundList'));
const TransitOutboundList = lazy(() => import('./views/Delivery/TransitList/TransitOutboundList'));
const StationDetailIndex = lazy(() => import('./views/Delivery/Zone/StationDetailIndex'));
const StationNetworkList = lazy(() => import('./views/Delivery/Zone/StationNetworkList'));
const StationCoverageMap = lazy(() => import('./views/Delivery/Zone/StationCoverageMap'));
const AuditLogs = lazy(() => import('./views/Login/AuditLogs'));
const ProfitSummary = lazy(() => import('./views/Reporting/ProfitSummary'));
const SimpleJournalEntry = lazy(() => import('./views/Accounting/Ledger/SimpleJournalEntry'));
const AccountLevelJournalEntry = lazy(() => import('./views/Accounting/Ledger/AccountLevelJournalEntry'));
const AccountBalance = lazy(() => import('./views/Accounting/Reporting/AccountBalance'));
const AccountTitleList = lazy(() => import('./views/Accounting/Ledger/AccountTitleList'));
const GeneralJournalList = lazy(() => import('./views/Accounting/Ledger/GeneralJournalList'));
const IncomeStatement = lazy(() => import('./views/Accounting/Reporting/IncomeStatement'));
const GeneralLedgerList = lazy(() => import('./views/Accounting/Ledger/GeneralLedgerList'));
const SyncToHRM = lazy(() => import('./views/Organization/Branch/SyncToHRM'));
const ProfitAndLoss = lazy(() => import('./views/Accounting/Reporting/ProfitAndLoss'));
const BalanceSheet = lazy(() => import('./views/Accounting/Reporting/BalanceSheet'));
const DailyAccountingList = lazy(() => import('./views/Accounting/Reporting/DailyAccountingList'));
const JournalSummaryList = lazy(() => import('./views/Accounting/Reporting/JournalSummaryList'));
const TrialBalanceList = lazy(() => import('./views/Accounting/Reporting/TrialBalanceList'));
const RoleList = lazy(() => import('./views/Organization/Role'));
const CreateRole = lazy(() => import('./views/Organization/Role/CreateRole'));
const PickupCashAdvanceList = lazy(() => import('./views/Pickup/Invoice/PickupCashAdvanceList'));
const CashOnDeliveryReport = lazy(() => import('./views/Accounting/Reporting/CashOnDeliveryReport'));
const SystemSettings = lazy(() => import('./views/Settings/SystemSettings'));
const ExcelUpload = lazy(() => import('./views/Delivery/ImportFromExternal/ExcelUpload'));


const Routes = () => {

    const resources = new Resources( );

  return (
    <Switch>
      <Redirect
        exact
        from={'/' }
        to={'/login'}
      />
      <RouteWithLayout
        component={Login}
        exact
        layout={MinimalLayout}
        path={'/login'}
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path={'/not-found'}
      />
         <RouteWithLayout
          component={AdminDashboard}
          exact
          layout={MainLayout}
          path={'/organization_admin_dashboard'}
         />
         <RouteWithLayout
           component={Create}
           exact
           layout={MainLayout}
           path={'/delivery_create'}
         />
         <RouteWithLayout
           component={DeliveryList}
           exact
           layout={MainLayout}
           path={'/way_delivery_list/:status'}
         />
         <RouteWithLayout
           component={DeliveryDetail}
           exact
           layout={MainLayout}
           path={'/way_delivery_admin_details/:id/:mode'}
         />
         <RouteWithLayout
           component={DeliveryDetail}
           exact
           layout={MinimalLayout}
           path={'/delivery_details/:id'}
         />
         <RouteWithLayout
           component={InvoiceList}
           exact
           layout={MainLayout}
           path={'/delivery_invoice_list'}
         />
         <RouteWithLayout
           component={Holiday}
           exact
           layout={MainLayout}
           path={'/settings_holidays'}
         />
         <RouteWithLayout
           component={PriceMethod}
           exact
           layout={MainLayout}
           path={'/pricing_price_package'}
         />
         <RouteWithLayout
           component={Zone}
           exact
           layout={MainLayout}
           path={'/org_delivery_zone'}
         />
         <RouteWithLayout
           component={Accounting}
           exact
           layout={MainLayout}
           path={'/finance_accounting'}
         />
         <RouteWithLayout
           component={DepositOS}
           exact
           layout={MainLayout}
           path={'/finance_os_deposit'}
         />
         <RouteWithLayout
           component={RefundOS}
           exact
           layout={MainLayout}
           path={'/finance_os_refund'}
         />
         <RouteWithLayout
           component={OSBalance}
           exact
           layout={MainLayout}
           path={'/finance_os_balance'}
         />
         <RouteWithLayout
           component={EmployeeCollectedClearance}
           exact
           layout={MainLayout}
           path={'/finance_employee_clearance'}
         />
         <RouteWithLayout
           component={AccountZone}
           exact
           layout={MainLayout}
           path={'/finance_income'}
         />
         <RouteWithLayout
           component={User}
           exact
           layout={MainLayout}
           path={'/users/:type'}
         />
         <RouteWithLayout
           component={AddressBook}
           exact
           layout={MainLayout}
           path={'/address_book/:type'}
         />
         <RouteWithLayout
           component={UserCreate}
           exact
           layout={MainLayout}
           path={'/users/:type/:guid'}
         />
         <RouteWithLayout
           component={AddressBookCreate}
           exact
           layout={MainLayout}
           path={'/address_book/:type/:guid'}
         />
         <RouteWithLayout
           component={AddressBookCreate}
           exact
           layout={MainLayout}
           path={'/address_book/:type/:guid/:owner'}
         />
         <RouteWithLayout
           component={AddressBookCreate}
           exact
           layout={MainLayout}
           path={'/address_book/:type/:guid/:owner/:name'}
         />
         <RouteWithLayout
           component={Promotion}
           exact
           layout={MainLayout}
           path={'/pricing_promo'}
         />
         <RouteWithLayout
           component={Expense}
           exact
           layout={MainLayout}
           path={'/org_expense'}
         />
         <RouteWithLayout
           component={Branch}
           exact
           layout={MainLayout}
           path={'/org_branch/:type'}
         />
         <RouteWithLayout
           component={ManagerAccountCreate}
           exact
           layout={MainLayout}
           path={'/org_branch/:type/:guid'}
         />
         <RouteWithLayout
           component={Contract}
           exact
           layout={MainLayout}
           path={'/pricing_contract'}
         />
         <RouteWithLayout
           component={PickupCreate}
           exact
           layout={MainLayout}
           path={'/pickup_create'}
         />
         <RouteWithLayout
           component={PickupCreate}
           exact
           layout={MainLayout}
           path={'/pickup_create/:id'}
         />
         <RouteWithLayout
           component={PickupList}
           exact
           layout={MainLayout}
           path={'/way_pickup_list/:status'}
         />
         <RouteWithLayout
           component={PickupDetail}
           exact
           layout={MainLayout}
           path={'/way_pickup_admin_details/:id/:mode'}
         />
         <RouteWithLayout
           component={PickupDetail}
           exact
           layout={MinimalLayout}
           path={'/pickup_details/:id'}
         />
         <RouteWithLayout
           component={DeliveryWay}
           exact
           layout={MainLayout}
           path={'/pickup_delivery_way/:pid/:did'}
         />
         <RouteWithLayout
           component={PickupInvoiceList}
           exact
           layout={MainLayout}
           path={'/finance_invoice_list'}
         />
         <RouteWithLayout
           component={UserCreate}
           exact
           layout={MainLayout}
           path={'/merchant_create/:type/:guid'}
         />
         <RouteWithLayout
           component={PickupInvoiceList}
           exact
           layout={MainLayout}
           path={'/merchant_invoice'}
         />
         <RouteWithLayout
           component={Payment}
           exact
           layout={MainLayout}
           path={'/merchant_payment/:type/:guid'}
         />
         <RouteWithLayout
           component={Payment}
           exact
           layout={MainLayout}
           path={'/merchant_received/:type/:guid'}
         />
         <RouteWithLayout
           component={PaymentHistory}
           exact
           layout={MainLayout}
           path={'/merchant_payment_history'}
         />
         <RouteWithLayout
           component={MerchantList}
           exact
           layout={MainLayout}
           path={'/merchant_list'}
         />
         <RouteWithLayout
           component={MerchantDetail}
           exact
           layout={MainLayout}
           path={'/merchant_detail/:id/:name/:pid/:cash'}
         />
         <RouteWithLayout
           component={EmployeeCollectedClearance}
           exact
           layout={MainLayout}
           path={'/deman_employee_collections'}
         />
         <RouteWithLayout
           component={UserCreate}
           exact
           layout={MainLayout}
           path={'/deman_create/:type/:guid'}
         />
         <RouteWithLayout
           component={DeliverymanList}
           exact
           layout={MainLayout}
           path={'/deman_list'}
         />
         <RouteWithLayout
           component={DeliverymanDetail}
           exact
           layout={MainLayout}
           path={'/deman_detail/:id/:name/:pid/:cash'}
         />
         <RouteWithLayout
           component={WayReportHome}
           exact
           layout={MainLayout}
           path={'/report_completed_list'}
         />
         <RouteWithLayout
           component={IncomeReport}
           exact
           layout={MainLayout}
           path={'/report_income'}
         />
         <RouteWithLayout
           component={ExpenseReport}
           exact
           layout={MainLayout}
           path={'/report_expense'}
         />
         <RouteWithLayout
           component={ToDeliverReport}
           exact
           layout={MainLayout}
           path={'/report_to_deliver_deliveryman/:personType'}
         />
         <RouteWithLayout
           component={ToDeliverReport}
           exact
           layout={MainLayout}
           path={'/report_to_deliver_merchant/:personType'}
         />
         <RouteWithLayout
           component={OverdueCountingReport}
           exact
           layout={MainLayout}
           path={'/report_overdue_count_by_date'}
         />
         <RouteWithLayout
           component={OverdueByDeliverymanReport}
           exact
           layout={MainLayout}
           path={'/report_overdue_by_deliveryman'}
         />
         <RouteWithLayout
           component={OverdueByMerchantReport}
           exact
           layout={MainLayout}
           path={'/report_overdue_by_merchant'}
         />
         <RouteWithLayout
           component={ActiveWayCountByTown}
           exact
           layout={MainLayout}
           path={'/report_active_way_count_by_township'}
         />
         <RouteWithLayout
           component={PriceMethodDetail}
           exact
           layout={MainLayout}
           path={'/pricing_package_detail/:id'}
         />
         <RouteWithLayout
           component={PriceMethodCreate}
           exact
           layout={MainLayout}
           path={'/pricing_package_create/:id'}
         />
         <RouteWithLayout
           component={UserProfile}
           exact
           layout={MainLayout}
           path={'/user_profile'}
         />
         <RouteWithLayout
           component={POICreate}
           exact
           layout={MainLayout}
           path={'/settings_add_poi/:type/:guid'}
         />
         <RouteWithLayout
           component={POIList}
           exact
           layout={MainLayout}
           path={'/settings_poi_list/:type'}
         />
         <RouteWithLayout
           component={ZoneCreate}
           exact
           layout={MainLayout}
           path={'/org_zone_create/:id'}
         />
         <RouteWithLayout
           component={ZoneDetail}
           exact
           layout={MainLayout}
           path={'/org_zone_detail/:id'}
         />
         <RouteWithLayout
           component={TrackingMap}
           exact
           layout={MainLayout}
           path={'/way_tracking'}
         />
           <RouteWithLayout
           component={ChatBox}
           exact
           layout={MinimalLayout}
           path={'/chatbox'}
         />
         <RouteWithLayout
           component={PriceMethodCreate}
           exact
           layout={MainLayout}
           path={'/pricing_package_create/:id/:exclusive'}
         />
         <RouteWithLayout
           component={ExclusivePricing}
           exact
           layout={MainLayout}
           path={'/price_exclusive'}
         />
         <RouteWithLayout
           component={TownsList}
           exact
           layout={MainLayout}
           path={'/settings_towns'}
         />
         <RouteWithLayout
           component={TownCreate}
           exact
           layout={MainLayout}
           path={'/settings_town_add/:guid'}
         />
      <RouteWithLayout
        component={AccessRequest}
        exact
        layout={MinimalLayout}
        path={'/access_request/:module/:token'}
      />
         <RouteWithLayout
          component={PickupReceiptList}
          exact
          layout={MainLayout}
          path={'/merchant_receipt'}
        />
         <RouteWithLayout
           component={DeliveryList}
           exact
           layout={MainLayout}
           path={'/way_delivery_list/:status/:date/:deliveryman'}
         />
         <RouteWithLayout
           component={TransitList}
           exact
           layout={MainLayout}
           path={'/way_transit_list/:status'}
         />
         <RouteWithLayout
           component={TransitDetail}
           exact
           layout={MainLayout}
           path={'/way_transit_detail/:id/:type'}
         />
         <RouteWithLayout
           component={TransitInboundList}
           exact
           layout={MainLayout}
           path={'/way_transit_inbound/:status'}
         />
         <RouteWithLayout
           component={TransitOutboundList}
           exact
           layout={MainLayout}
           path={'/way_transit_outbound/:status'}
         />
         <RouteWithLayout
           component={StationDetailIndex}
           exact
           layout={MainLayout}
           path={'/org_zone_station_detail/:id/:type'}
         />
         <RouteWithLayout
           component={StationNetworkList}
           exact
           layout={MainLayout}
           path={'/org_zone_station_network'}
         />
         <RouteWithLayout
           component={StationCoverageMap}
           exact
           layout={MainLayout}
           path={'/org_zone_station_map'}
         />
         <RouteWithLayout
           component={AuditLogs}
           exact
           layout={MainLayout}
           path={'/audit_logs'}
         />
         <RouteWithLayout
           component={ProfitSummary}
           exact
           layout={MainLayout}
           path={'/report_gross_margin'}
         />
         <RouteWithLayout
           component={SimpleJournalEntry}
           exact
           layout={MainLayout}
           path={'/accounting_transaction_simple_journal_entry'}
         />
         <RouteWithLayout
           component={AccountLevelJournalEntry}
           exact
           layout={MainLayout}
           path={'/accounting_transaction_account_level_journal_entry'}
         />
         <RouteWithLayout
           component={AccountBalance}
           exact
           layout={MainLayout}
           path={'/accounting_accounts_balance'}
         />
         <RouteWithLayout
           component={AccountTitleList}
           exact
           layout={MainLayout}
           path={'/accounting_accounts_title_list'}
         />
         <RouteWithLayout
           component={GeneralJournalList}
           exact
           layout={MainLayout}
           path={'/accounting_transaction_general_journal_list'}
         />
         <RouteWithLayout
           component={IncomeStatement}
           exact
           layout={MainLayout}
           path={'/accounting_financial_income_statement_list'}
         />
         <RouteWithLayout
           component={GeneralLedgerList}
           exact
           layout={MainLayout}
           path={'/accounting_transaction_general_ledger_list'}
         />
        <RouteWithLayout
          component={SyncToHRM}
          exact
          layout={MainLayout}
          path={'/org_sync_to_hrm'}
        />
         <RouteWithLayout
           component={ProfitAndLoss}
           exact
           layout={MainLayout}
           path={'/accounting_financial_profit_and_loss'}
         />
         <RouteWithLayout
           component={BalanceSheet}
           exact
           layout={MainLayout}
           path={'/accounting_financial_balance_sheet'}
         />
         <RouteWithLayout
           component={DailyAccountingList}
           exact
           layout={MainLayout}
           path={'/accounting_financial_daily_accounting'}
         />
         <RouteWithLayout
           component={JournalSummaryList}
           exact
           layout={MainLayout}
           path={'/accounting_financial_journal_summary'}
         />
         <RouteWithLayout
           component={TrialBalanceList}
           exact
           layout={MainLayout}
           path={'/accounting_financial_trial_balance'}
         />
         <RouteWithLayout
           component={RoleList}
           exact
           layout={MainLayout}
           path={'/org_role_list'}
         />
         <RouteWithLayout
           component={CreateRole}
           exact
           layout={MainLayout}
           path={'/org_role_create/:guid'}
         />
         <RouteWithLayout
           component={UserProfile}
           exact
           layout={MainLayout}
           path={'/user_profile/:guid'}
         />
         <RouteWithLayout
           component={PickupCashAdvanceList}
           exact
           layout={MainLayout}
           path={'/merchant_cash_advance'}
         />
         <RouteWithLayout
           component={CashOnDeliveryReport}
           exact
           layout={MainLayout}
           path={'/deman_employee_cod_summary'}
         />
         <RouteWithLayout
           component={SystemSettings}
           exact
           layout={MainLayout}
           path={'/settings_systems'}
         />
         <RouteWithLayout
           component={ExcelUpload}
           exact
           layout={MainLayout}
           path={'/way_deli_upload_ways/:pid'}
         />
      <Redirect to={'/login'} />
    </Switch>
  );
};

export default Routes;
